import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  makeStyles
} from "@material-ui/core";
import Login from "./Login";
import Grid from '@mui/material/Grid';
import useUserData from "../../Hooks/useUser/useUserdata";
import CloseIcon from '@mui/icons-material/Close';




const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "#d99308",
    fontSize: "20px",
  },
  icon: {
    color: "white"
  }
}));

const LoginDrawerComponent = (props) => {
  const [userData, showLoginDrawer, hideLoginDrawer] = useUserData();
  console.log(userData);
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(true);
  return (
    <>
      <Drawer
        open={userData.showDrawer}
        onClose={() => hideLoginDrawer(false)}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '400px' }, // Full width for mobile, 400px for larger screens
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'background.default',
            padding: 2,
          },
        }}
      >
        {/* Close Button */}
        <Grid item xs={12} sx={{ alignItems: 'left' }}>
          <IconButton
            onClick={() => {
              hideLoginDrawer(false);
            }}
            sx={{ position: 'absolute', top: 8, left: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: '100%',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >


          {/* Login Component */}
          <Grid item xs={12}>
            <Login onClose={hideLoginDrawer} />
          </Grid>
        </Grid>
      </Drawer >
    </>
  );
}
export default LoginDrawerComponent;