import React  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const bannerImage1 = require('../../../images/bg_l1.png');
const bannerImage2 = require('../../../images/bg1.jpeg');
const bannerImage3 = require('../../../images/bg4.jpeg');
const bannerImage4 = require('../../../images/bg2.jpg');
const bannerImage5 = require('../../../images/bg3.jpg');
const bannerImage6 = require('../../../images/bg6.jpeg');

const Banner = (props) => {
    
        return (
            <Carousel 
           // style={{width:"100%", height:"200px !important"}}
            autoPlay={true}
            infiniteLoop={false}
            // interval={3000}
            showArrows={true}
            showIndicators={false}
            showThumbs={false}
            swipeable={true}
            
            >
                <div style={{width:"100%", }}>
                    <img src={bannerImage1} className='banner-image' />
                    <div className="legend ">
                        <h1 className='slider-text'>We serve Fresh Vegestables &amp; Fruits</h1>
                        <h2 className='sub-heading'>We deliver farm fresh vegetables &amp; fruits</h2>
                        <p><a className='btn btn-primary' href= "/products">View Details</a></p>
                    </div>
                </div>
                <div>
                    <img src={bannerImage2} className='banner-image'/>
                    <div className="legend ">
                        <h1 className='slider-text'>We serve Fresh Vegestables &amp; Fruits</h1>
                        <h2 className='sub-heading'>We deliver farm fresh vegetables &amp; fruits</h2>
                        <p><a className='btn btn-primary'>View Details</a></p>
                    </div>
                </div>
                <div>
                    <img src={bannerImage3} className='banner-image'/>
                    <div className="legend ">
                        <h1 className='slider-text'>100% Fresh &amp; best quality items</h1>
                        <h2 className='sub-heading'>We deliver best quality vegetables &amp; fruits</h2>
                        <p><a className='btn btn-primary'>View Details</a></p>
                    </div>
                </div>
                <div>
                    <img src={bannerImage4} className='banner-image'/>
                    <div className="legend ">
                        <h1 className='slider-text'>100% Fresh &amp; Hygiene Foods</h1>
                        <h2 className='sub-heading'>We deliver Hygiene vegetables &amp; fruits</h2>
                        <p><a className='btn btn-primary'>View Details</a></p>
                    </div>
                </div>
                <div>
                    <img src={bannerImage5} className='banner-image'/>
                    <div className="legend ">
                    <h1 className='slider-text'>100% Fresh &amp; Hygiene Foods</h1>
                        <h2 className='sub-heading'>We deliver Hygiene vegetables &amp; fruits</h2>
                        <p><a className='btn btn-primary'>View Details</a></p>
                    </div>
                </div>
                <div>
                    <img src={bannerImage6} className='banner-image'/>
                    <div className="legend ">
                    <h1 className='slider-text'>100% Fresh &amp; Hygiene Foods</h1>
                        <h2 className='sub-heading'>We deliver Hygiene vegetables &amp; fruits</h2>
                        <p><a className='btn btn-primary'>View Details</a></p>
                    </div>
                </div>
            </Carousel>
        );
         
};

export default Banner;