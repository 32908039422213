import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const myStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#fff'
  }
}))

const phoneInput = (props, ref) => {
  const classes = myStyles()

  return (

    <TextField
      {...props}
      // InputProps={{
      //   className: classes.input
      // }}
      inputRef={ref}
      fullWidth
      size='small'
      label='Phone Number'
      variant='outlined'
      name='phone'
      style={{ marginTop: '5px', width: '300px' }}
    />
  )
}
export default forwardRef(phoneInput)
