import React, { useState, Fragment } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Button from '@mui/material/Button';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import Toast from '../../Utils/toast';
import useUserData from '../../Hooks/useUser/useUserdata';

const Product = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [isAddedTocart, setIsAddedTocart] = useState(false);
  const { product } = props;
  const [cartData, addProduct] = useUpdateCart();
  const [, showLoginDrawer, , isUserAutheticated, getCurrentUserData] = useUserData();
  const handleAddProduct = (product) => {
    if (isUserAutheticated() == true) {
      const userDataObj = getCurrentUserData();
      product.quantity = quantity;
      addProduct([cartData.products, product, userDataObj.uid]);
      setIsAddedTocart(true);
      setQuantity(1);
    } else {
      showLoginDrawer(true);
    }

  }

  return (
    <Fragment>
      <Card sx={{ maxWidth: 'auto' }}>
        <CardMedia
          sx={{ pt: 2 }}
          component="img"
          height="194"
          image={product.images[0]}
          // image={require(`../../images/${product.images[0]}`)}
          alt="Paella dish"
        />
        <CardContent sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Typography gutterBottom variant="small" component="div">
            {product.title}
          </Typography>
        </CardContent>


        <CardActions sx={{ display: 'flex' }}>
          <Button variant="outlined" size="small" color="success" onClick={() => { setQuantity(quantity => quantity - 1) }} disabled={quantity <= 1}>
            <RemoveIcon />
          </Button>
          <CardContent style={{padding:5}}>
            <Typography variant="h6" size="small" component="div" color="grey" >
              {quantity}
            </Typography>
          </CardContent>
          <Button variant="outlined" size='small' color="success" onClick={() => { setQuantity(quantity => quantity + 1) }}>
            <AddIcon />
          </Button>
          <Button variant="outlined" size='small' color='success' onClick={() => { handleAddProduct(product) }}>
            Add
          </Button>

          {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}

        </CardActions>
      </Card>
      <Toast open={isAddedTocart} message={'Product Added To Cart!'} type={'success'} />
    </Fragment>
  );
}

export default Product;