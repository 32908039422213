import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Badge from '@mui/material/Badge';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import useUpdateCart from "../../Hooks/useUpdateCart/useUpdateCart";
import useUserData from "../../Hooks/useUser/useUserdata";




const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "#d99308",
    fontSize: "20px",
  },
  icon: {
    color: "white"
  }
}));

const DrawerComponent = (props) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cartData, addProduct, removeProduct, decreaseProductQuantity,
    increaseProductQuantity,
    getCartProducts, resetCartData] = useUpdateCart();
  const [userData, showLoginDrawer, hideLoginDrawer, isUserAutheticated, getCurrentUserData, logout] = useUserData();
  const navigate = useNavigate();
  const handleLogout = () => {
    resetCartData();
    logout();
    navigate("/");
    setOpenDrawer(false);
    props.showLoginDrawer()

  }
  return (
    <>
      <Drawer
        color={'white'}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div className=" navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item" onClick={() => setOpenDrawer(false)}>
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </li>

            <li className="nav-item" onClick={() => setOpenDrawer(false)}>
              <NavLink className="nav-link" to="/products">
                Products
              </NavLink></li>
            <li className="nav-item" onClick={() => setOpenDrawer(false)}>
              <NavLink className="nav-link" to="/about-us">
                About
              </NavLink></li>
            {/* <li className="nav-item" onClick={() => setOpenDrawer(false)}>
              <NavLink className="nav-link" to="/blog">
                Blog
              </NavLink>
            </li> */}
            <li className="nav-item" onClick={() => setOpenDrawer(false)}>
              <NavLink className="nav-link" to="/contact-us">
                Contact
              </NavLink>
            </li>
            {
              (props.isUserAutheticated()) ? (<><li className="nav-item">

                <a className="nav-link" onClick={() => { handleLogout() }}>
                  Logout
                </a>
              </li> <li className="nav-item" onClick={() => setOpenDrawer(false)}>
                  <NavLink className="nav-link" to="/orders">
                    Orders
                  </NavLink>
                </li>

                <li className="nav-item cta cta-colored">
                  <NavLink className="nav-link" to="/cart" onClick={() => setOpenDrawer(false)}>
                    <Badge badgeContent={props.cartTotal} color="success">
                      <AddShoppingCartIcon />
                    </Badge>
                  </NavLink>
                </li>

              </>) : <li className="nav-item">
                <a className="nav-link" onClick={() => {
                  setOpenDrawer(false);
                  props.showLoginDrawer()
                }}>
                  Login
                </a>
              </li>
            }


          </ul>
        </div>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
          aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
          <MenuIcon />
        </button>
      </IconButton>
    </>
  );
}
export default DrawerComponent;